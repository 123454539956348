import React from "react"

import styled from "styled-components"

import { Link } from "gatsby"

import NewsTileMedia from "./news-tile-media"

var moment = require("moment")

var deburr = require('lodash/deburr');

const Tile = styled.div`
  flex-basis: 100%;
  padding: 0rem;
  visibility: hidden;

  :nth-child(n + 2) {
    // margin-top: 3rem;
  }

  > div {
    position: relative;
    transition: opacity 0.7s;
  }

  > div:hover {
    opacity: 0.5;
    transition: opacity 0.3s;
  }

  &&.hide-tile {
    display: none;
  }


  

  @media (min-width: 767px) {
    flex-basis: ${props => props.fullWidth ? "100%" : "50%"};


    > div > a > div {
      height: ${ props => props.fullWidth ? "calc((100vw - 2rem) * 0.5625)}" : "calc((50vw - 2rem) * 0.5625)}"};
    }

    .desktop-image {
      height: 100%;
    }

  }
`

const Information = styled.div`
  position: absolute;
  bottom: 0;
  // margin-top: 2.125rem;
  margin: 0 0 1rem 1.5rem;
  // color: #272727;
  color: #eeede9;


  a {
    // color: #272727;
    color: #eeede9;
    text-decoration: none;
  }
`

const Date = styled.div`
  margin-top: 3rem;
  color: #272727;
  cursor: default;
`

let sanitizeTag = (item) => {
  return deburr(item.toLowerCase().replace(/\s+/g, '-'));
}

let tags = (tags) => {
  if(tags === undefined) return;

  let tagsArray = [];

  tags.forEach(tag => {
    tagsArray.push(tag.title)
  })

  return tagsArray.join(", ")
}

let classTags = (tags) => {
  if(tags === undefined) return;

  let tagsArray = [];

  tags.forEach(tag => {
    tagsArray.push(sanitizeTag(tag.title))
  })

  return tagsArray.join(" ")
}

export default props => {
  let data = props.data

  return (
    <Tile className={`news-tile ${classTags(data.tags)}`} fullWidth={data.fullWidth}>
      <div>
        <Link to={`/${data.uri || data.ctaLink}`}>
          <NewsTileMedia data={data.header !== undefined ? data.header[0] : data} />
        </Link>
        <Information>
          <h3>
            <Link to={`/${data.uri || data.ctaLink}`}>{data.title || data.titleField}</Link>
            <br/>
            <Link to={`/${data.uri || data.ctaLink}`}>{data.ctaText || tags(data.tags)}</Link>
          </h3>
        </Information>

        {/* <Date className="date">
          {moment(data.date).format("D/MM/YYYY")}
        </Date> */}
      </div>
    </Tile>
  )
}
