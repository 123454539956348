import React from "react"

import Img from "gatsby-image"

import styled from "styled-components"

const Container = styled.div`

  .gatsby-image-wrapper {
    height: 100%;
  }

  > video {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  @media(max-width: 576px) {
    .desktop-image, video {
      display: ${props => props.hasMobileImage ? "none" : "block"};
    }
  }
`

const DesktopImage = styled.div`

`


const MobileImage = styled.div`
  height: 100%;
  width: 100%;
  object-fit: cover;

  @media(min-width: 576px) {
    display: none;
  }
`


export default props => {

  if ((props.data.image.length === 0) & (props.data.video.length === 0)) return null

  
  return (
    <Container hasMobileImage={props.data.mobileimage?.length > 0}>
      {props.data.video[0] ? (
        <>
        <MobileImage>
          {
            props.data.mobileimage && <Img fluid={props.data.mobileimage[0].imageFile.childImageSharp.fluid} />
          }
        </MobileImage>
        
        <video autoPlay muted playsInline loop>
          <source src={props.data.video[0].url} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        </>
      ) : (
        <>
        <MobileImage>
          {
            props.data.mobileimage && <Img fluid={props.data.mobileimage[0].imageFile.childImageSharp.fluid} />
          }
        </MobileImage>
        <DesktopImage className="desktop-image">
          <Img fluid={props.data.image[0].imageFile.childImageSharp.fluid} />
        </DesktopImage>
        </>
      )}
    </Container>
  )
}
